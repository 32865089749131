.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;

	.content-title-wrapper {
		display: flex;
		align-items: center;
		background: #e7e7e7;
		border-top-right-radius: 2.7rem;
		border-top-left-radius: 2.7rem;
		border-bottom: 1px solid #fff;

		.content-title-container {
			background: #050505;
			font-size: xx-large;
			color: #fff;
			border-top-left-radius: 2.7rem;
			width: fit-content;
			display: flex;

			.content-title {
				padding: 1rem 0 1rem 2rem;
				margin-left: 2rem;
				text-transform: capitalize;
			}

			.content-title-parallelogram {
				width: 50px;
				transform: skew(-20deg);
				position: relative;
				right: -2rem;
				background: #050505;
				z-index: 1;
			}
		}

		.content-subtitle {
			margin-left: 4rem;
		}
	}

	.content-container {
		flex-grow: 1;
		border-bottom-left-radius: 2.7rem;
		border-bottom-right-radius: 2.7rem;
		margin: 2rem;
		overflow-y: auto;
	}
}
