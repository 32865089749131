.navigation-wrapper {
	position: fixed;
	z-index: 1000;
	bottom: 10px;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: #fff;
	font-size: small;

	.navigation-container {
		background-color: #4d4d4d;
		padding: 0.5rem;
		border-radius: 1rem;
		display: flex;
		gap: 1rem;

		.navigation-title {
			min-width: 80px;
			background-color: #050505;
			border: 4px solid #050505;
			padding: 0.5rem;
			border-radius: 1rem;
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: large;
			line-height: 1;
			text-align: right;
			justify-content: flex-end;
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: #fff;
				color: #050505;
			}
		}

		.navigation {
			min-width: 100px;
			background-color: #3e3e3e;
			border: 4px solid #3e3e3e;
			padding: 0.5rem;
			border-radius: 1rem;
			display: flex;
			align-items: center;
			gap: 1rem;

			.navigation-item {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				transition: all 0.3s ease-in-out;
				padding: 0 1rem;
				cursor: pointer;
				border: 1px solid #888888;
				border-radius: 1rem;

				&:hover {
					border: 1px solid #fff;
				}
			}
		}
	}

	a {
		display: contents;
		color: inherit;
		text-decoration: none;
	}
}
