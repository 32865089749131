button[type="submit"] {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 12px 20px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
	width: 100%;
	font-size: 16px;

	&:hover {
		background-color: #ccc;
		cursor: pointer;
	}
}
