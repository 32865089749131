.form-group {
	margin: 0 0 1rem 0;

	input {
		height: 40px;
		margin: 0 !important;
		border: 1px solid #ccc;
		border-radius: 6px;
		padding: 1rem;
		min-width: 100%;
	}

	label {
		font-weight: 500;
		font-size: 1rem;
		display: block;
	}
}
