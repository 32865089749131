.blog-grid {
	display: grid;
	padding: 0 1rem 1rem 1rem;
	grid-template-columns: repeat(4, 1fr);
}

.blog-gap-2 {
	gap: 0.5rem;
}

.blog-image-container {
	position: relative;
	cursor: pointer;
}

.blog-hover-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: opacity 0.2s ease-in-out;
}

.blog-hover-content:hover {
	opacity: 1;
}

.blog-title {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.blog-category {
	font-size: 0.8rem;
	font-weight: bold;
	letter-spacing: 1px;
}
