.portfolio-grid {
	display: grid;
	padding: 1.5rem;
	grid-template-columns: repeat(12, 1fr);
	height: fit-content;
	gap: 0.5rem;
	width: 100%;
}

.portfolio-image-container {
	position: relative;

	img {
		height: 100%;
		width: 100%;
	}
}

.portfolio-hover-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: opacity 0.2s ease-in-out;
}

.portfolio-hover-content:hover {
	opacity: 1;
}

.portfolio-title {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.portfolio-category {
	font-size: 0.8rem;
	font-weight: bold;
	letter-spacing: 1px;
}
