.addbutton-image-container {
	position: relative;
	cursor: pointer;

	img {
		height: 100%;
		width: 100%;
	}
}

.addbutton-hover-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: opacity 0.2s ease-in-out;
}

.addbutton-hover-content:hover {
	opacity: 1;
}

.addbutton-title {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.addbutton-category {
	font-size: 0.8rem;
	font-weight: bold;
	letter-spacing: 1px;
}

.option-bar {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-right: 1rem;
	border-right: 1px solid;

	.delete-btn {
		border: none;
		background-color: transparent;
		cursor: pointer;
		font-weight: 500;
		font-size: 1rem;
		transition: color 0.2s ease-in-out;
		padding: 1rem 0;

		&:hover {
			color: #ff0000;
		}
	}

	.update-btn {
		width: 100%;
		padding: 0.5rem 1rem;
		border: none;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
		border-radius: 6px;
		background: #8bc34a;
		color: #ffffff;
		font-weight: 700;

		&:hover {
			cursor: pointer;
			background-color: #000;
			color: #fff;
		}
	}

	.thumbnail {
		width: 100px;
		height: 100px;
		object-fit: cover;
		border: 1px solid #ccc;
		border-radius: 6px;
		margin-bottom: 1rem;
		align-self: center;
	}
}

.content-title-editbar {
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	padding: 1rem 2rem;
	background: #d0ced9;
}

.file-list {
	margin-bottom: 1rem;
	height: 200px;
	border-radius: 6px;
	width: 100%;
	border: 1px solid #ccc;
	padding: 1rem;
	overflow-y: auto;
	font-size: x-small;
	color: #000;
	cursor: default;
}

.file-list-item {
	display: flex;
	width: 100%;
	justify-content: space-between;
	line-height: 0;
	align-items: center;
	border-left: 2px solid transparent;
	padding-left: 1rem;

	&:hover {
		background-color: rgb(240, 240, 240);
	}

	button {
		cursor: pointer;
	}

	&.selected {
		border-left: 2px solid #000;
	}
}
