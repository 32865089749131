.website-grid {
	display: grid;
	padding: 0 1rem 1rem 1rem;
	grid-template-columns: repeat(8, 1fr);
	gap: 0.5rem;
}

.website-image-container {
	position: relative;
	cursor: default;
}

.website-hover-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: opacity 0.2s ease-in-out;

	button {
		cursor: pointer;
	}
}

.website-hover-content:hover {
	opacity: 1;
}
