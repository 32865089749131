* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	font-family: "Roboto", sans-serif;
	background-color: #f5f5f5;
	color: #333;
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: 100vh;
	font-size: 1.6rem;
}

#root {
	border-radius: 3rem;
	border: 2px solid #4d4d4d;
	margin: 2rem 3rem 4rem 3rem;
	height: calc(100vh - 6rem);
}

::-webkit-scrollbar {
	width: 0.5rem;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}
